<template>
	<div class="basic">
		<div class="app-page">
			<div class="title">Good Morning Mark</div>
			<div class="article">
				<img class="smallImage left" src="http://cleversystems.com:8081/img/nfl/gb.png" />
				<div class="story">
					<div class="headline">You were right about the Packers!</div>
					<div class="dateline">2h Ago</div>
					<div class="content">As you predicted, the Packers beat the Bears yesterday by a whopping...</div>
				</div>
			</div>
			<div class="article">
				<div class="story">
					<div class="headline">Only Mary knew Apple would crash this week</div>
					<div class="dateline">Yesterday</div>
					<div class="content">
						<span class="user">Mary</span> knew that the WSJ Reporting would lead to a downturn...
					</div>
				</div>
				<img class="smallImage right" src="http://cleversystems.com:8081/img/stocks/apple.png" />
			</div>
			<div class="article">
				<img class="smallImage left" src="http://cleversystems.com:8081/img/nfl/pit.png" />
				<div class="story">
					<div class="headline">Jeremy holds first place with bold Steelers pick.</div>
					<div class="dateline">Sunday</div>
					<div class="content">
						<span class="user">Jeremy</span> was very confident the Steelers would beat the Browns, and he was rewarded...
					</div>
				</div>
			</div>
			<div class="article">
				<div class="story">
					<div class="headline">Uncle Tony is giving you bad advice about Twitter</div>
					<div
						class="content"
					>Uncle Tony has been a strong influencer on a lot of your bad choices this week...</div>
				</div>
				<img class="smallImage right" src="http://cleversystems.com:8081/img/stocks/twitter.png" />
			</div>
		</div>
		<div class="bottom-spacer" />
	</div>
</template>

<script>
export default {
	name: "News",
}

</script>

<style scoped>
.article {
	display: inline-block;
	margin: 5px;
	border-radius: 5px;
	min-height: 120px;
	border: 1px solid white;
	background-color: black;
	padding: 2px;
	vertical-align: top;
}
.story {
	display: inline-block;
	width: 70%;
}
.dateline {
	font-size: 12px;
	color: #3399ff;
}
.headline {
	display: inline-block;
	font-size: 24px;
	color: #99ccff;
}
.smallImage {
	vertical-align: top;
	display: inline-block;
	width: 70px;
}
.content {
	font-size: 18px;
	color: white;
}
</style>
